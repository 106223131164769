<template>
  <div class="border-box" :class="{'linear-gradient': bg}" :style="styles">
      <slot></slot>
      <div class="b top-left"></div>
      <div class="b top-right"></div>
      <div class="b bottom-left"></div>
      <div class="b bottom-right"></div>
  </div>
</template>
<script>

export default {
    name: 'BorderBox',
    props: {
        bg: {
            default: false
        },
        styles: {
            default: ()=>{
             return { border: '1px solid rgba(170, 170, 170, 0.5)' }
            }
        }
    },
}
</script>

<style lang="less">
.border-box{
    border-radius: 10px;
    width: 100%;
    height: 100%;
    position: relative;
    &.linear-gradient{
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(21, 67, 130, 0.8) 50%);
    }

    .b{
        width: 10px;
        height: 10px;
        position: absolute;
    }
    .top-left{
        background: url('../assets/images/comons/top-left.png') no-repeat; 
        top: -1px;
        left: -1px;
    }
    .top-right{
        background: url('../assets/images/comons/top-right.png') no-repeat; 
        top: -1px;
        right: -1px;
    }
    .bottom-left{
        background: url('../assets/images/comons/bottom-left.png') no-repeat; 
        bottom: -1px;
        left: -1px;
    }
    .bottom-right{
        background: url('../assets/images/comons/bottom-right.png') no-repeat; 
        bottom: -1px;
        right: -1px;
    }
}
</style>